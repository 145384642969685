import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Career from "./pages/Career";
import ApplyJob from "./pages/ApplyJob";
import Template404 from "./pages/Template404";

const App = () => {
  return (
    <Router>
      <div>
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/apply-job/:jobId" element={<ApplyJob />} />
          <Route path="*" element={<Template404 />} />
        </Routes>
      </main>
      <Footer />
    </div>
    </Router>
  );
};

export default App;
