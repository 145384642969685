import axios from "axios"
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react"
import { useParams } from "react-router"
import InputBox from "../components/InputBox"
import phone from "phone"
import JobInformationSkeleton from "../Skeletons/JobInformationSkeleton"

interface Job {
	id: number
	job_title: string
	job_description: string
	job_category: string
	salary: number
	job_requirements: string
	image: string
}

interface ApplicationData {
	name: string
	email: string
	phone_number: string
}

interface FormErrors {
	name?: string
	email?: string
	phone_number?: string
	cv?: string
}

const ApplyJob: FC<{}> = () => {
	const { jobId } = useParams<{ jobId: string }>()
	const [jobData, setJobData] = useState<Job>({
		id: 0,
		job_title: "",
		job_description: "",
		job_category: "",
		salary: 0,
		job_requirements: "",
		image: "",
	})
	const [applicationData, setApplicationData] = useState<ApplicationData>({
		name: "",
		email: "",
		phone_number: "",
	})
	const [formErrors, setFormErrors] = useState<FormErrors>({})
	const [formResponse, setFormResponse] = useState<string>("")
	const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [selectedFile, setSelectedFile] = useState<File | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/users/jobs/${jobId}`
			)
			setJobData(response.data)
			setIsLoading(false)
		}

		fetchData()
	}, [jobId])

	const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target

		setApplicationData({
			...applicationData,
			[name]: value,
		})
		setFormErrors({
			...formErrors,
			[name]: "",
		})
		setFormResponse("")
	}

	const validate = (): FormErrors => {
		const errors: FormErrors = {}

		if (!applicationData.name.trim()) {
			errors.name = "Name is Required"
		}
		if (!applicationData.email.trim()) {
			errors.email = "Email is Required"
		} else if (!/\S+@\S+\.\S+/.test(applicationData.email)) {
			errors.email = "Email is Incorrect"
		}
		if (!applicationData.phone_number.trim()) {
			errors.phone_number = "Phone Number is Required"
		} else {
			const { isValid } = phone(applicationData.phone_number)
			if (!isValid) {
				errors.phone_number = "Phone Number is Incorrect"
			}
		}
		if (!selectedFile) {
			errors.cv = "CV is Required"
		}

		return errors
	}

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault()

		const errors: FormErrors = validate()
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors)
			return
		}

		try {
			setIsFormDisabled(true)
			const formData = new FormData()
			// Add other form data
			formData.append("name", applicationData.name)
			formData.append("email", applicationData.email)
			formData.append("phone_number", applicationData.phone_number)
			// Append the file
			if (selectedFile) {
				formData.append("cv", selectedFile)
			}

			await axios.post(
				`${process.env.REACT_APP_API_URL}/users/apply/${jobId}`,
				formData
			)

			setFormResponse("Applied Successfully")
			setIsFormDisabled(false)
			setApplicationData({ name: "", email: "", phone_number: "" })
			setSelectedFile(null)
		} catch (err: any) {
			setIsFormDisabled(false)
		}
	}

	const handleFileChange = (file: File | null) => {
		setFormErrors({
			...formErrors,
			cv: "",
		})
		setSelectedFile(file)
	}

	return (
		<>
			<section className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark">
				<div className="container mx-auto">
					<div className="flex flex-wrap -mx-4">
						<div className="w-full px-4">
							<div className="mx-auto mb-[60px] max-w-[510px] text-center">
								<span className="text-primary mb-2 block text-lg font-semibold">
									Apply For Job
								</span>
								<h2 className="text-dark mb-3 text-3xl leading-[1.208] font-bold sm:text-4xl md:text-[40px]">
									Join Our Team
								</h2>
								<p className="text-body-color text-base">
									At Gig Masters, we are always looking for talented and
									passionate individuals to join our team.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="container mx-auto px-4">
					<div className="flex flex-col lg:flex-row gap-8">
						<div className="lg:w-3/4">
							{isLoading && <JobInformationSkeleton />}
							{!isLoading && (
								<>
									<img
										src={jobData.image}
										alt="portfolio"
										className="w-full h-72 object-cover mb-7 rounded-md border border-gray-4"
									/>
									<h1 className="text-3xl font-bold ">{jobData.job_title}</h1>
									<p className="text-base mb-4 text-primary">
										{jobData.job_category}
									</p>
									<p className="text-base mb-4 text-body-color">
										{jobData.job_description}
									</p>
									<h1 className="text-xl mb-2 font-semibold ">
										Job Requirements
									</h1>
									<p className="text-base mb-4 text-body-color">
										{jobData.job_requirements}
									</p>
								</>
							)}
						</div>
						<div className="lg:w-1/4 w-full">
							<div className="bg-white rounded-lg border border-gray-2 shadow-lg">
								<div className="px-6 py-4 bg-gray-1 rounded-tl-lg rounded-tr-lg">
									<h2 className="text-lg font-bold">Application Form</h2>
								</div>
								<div className="p-6">
									<form>
										<InputBox
											type="text"
											name="name"
											placeholder="Full Name*"
											value={applicationData.name}
											onValueChange={handleInputValueChange}
											error={formErrors.name}
											isDisabled={isFormDisabled}
										/>
										<InputBox
											type="text"
											name="email"
											placeholder="Your Email*"
											value={applicationData.email}
											onValueChange={handleInputValueChange}
											error={formErrors.email}
											isDisabled={isFormDisabled}
										/>
										<InputBox
											type="text"
											name="phone_number"
											placeholder="+39 02 12345678*"
											value={applicationData.phone_number}
											onValueChange={handleInputValueChange}
											error={formErrors.phone_number}
											isDisabled={isFormDisabled}
										/>
										<FileInput
											handleFileChange={handleFileChange}
											error={formErrors.cv}
											isDisabled={isFormDisabled}
										/>
									</form>
								</div>
							</div>
							<div className="mt-7">
								<button
									onClick={handleSubmit}
									type="submit"
									className="w-full rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90 disabled:cursor-default disabled:text-gray-500 disabled:bg-gray-2 disabled:border-gray-2"
									disabled={isFormDisabled}
								>
									{isFormDisabled ? "Uploading File..." : "Apply Now"}
								</button>
								{formResponse.length > 0 && (
									<p className="mt-[10px] text-sm text-primary">
										{formResponse}
									</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default ApplyJob

interface FileInputProps {
	handleFileChange: (file: File | null) => void
	error?: string
	isDisabled: boolean
}

const FileInput: FC<FileInputProps> = ({
	handleFileChange,
	error,
	isDisabled,
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] || null
		handleFileChange(file)
	}

	return (
		<>
			<label className="mb-[10px] block text-sm font-medium text-dark dark:text-white">
				Upload Your CV
			</label>
			<div className="relative">
				<input
					type="file"
					onChange={handleChange}
					accept=".pdf"
					disabled={isDisabled}
					className={`w-full cursor-pointer rounded-md border text-dark-6 outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-gray-2 file:py-3 file:px-5 file:text-body-color file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 ${
						error ? "border-red-500" : "border-stroke"
					}`}
				/>
			</div>
			{error && <p className="mt-[10px] text-sm text-red">{error}</p>}
		</>
	)
}
