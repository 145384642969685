import { Element } from "react-scroll"
import AboutSection from "../components/AboutSection"
import ContactSection from "../components/ContactSection"
import CtaSection from "../components/CtaSection"
import Hero from "../components/HeroSection"
import ServicesSection from "../components/ServicesSection"

const Home = () => {    
  return (
    <>
        <Hero />
        <Element name="services-section">
          <ServicesSection />
        </Element>
        <Element name="about-section">
          <AboutSection />
        </Element>
        <Element name="contact-section">
          <ContactSection />
        </Element>
        <CtaSection />
    </>
  )
}

export default Home