import React, { FC } from "react";
import HeroImage from "../assets/images/hero_image.png"
import { scroller } from "react-scroll";

const Hero:FC = () => {
  const handleClick = () => {
		scroller.scrollTo("contact-section", {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
	}

  return (
    <>
      <div id="hero-section" className="relative bg-white pb-[110px] pt-[120px] lg:pt-[100px] px-4">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-5/12 lg:flex lg:items-center">
              <div className="hero-content">
                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-dark sm:text-[42px] lg:text-[40px] xl:text-5xl">
                Transform Your Digital Presence
                </h1>
                <p className="mb-8 max-w-[480px] text-base text-body-color ">
                Unlock your business potential with our expert website design, development, and digital marketing services. Our dedicated team ensures you achieve your goals and stand out in the digital world.
                </p>
                <ul className="flex flex-wrap items-center">
                  <li>
                    <div
                      onClick={handleClick}
                      className="inline-flex items-center justify-center rounded-md bg-primary px-6 py-3 text-center text-base font-medium text-white hover:bg-primary/90 lg:px-7 cursor-pointer"
                    >
                      Contact Us
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative z-10 inline-block pt-11 lg:pt-0">
                  <img
                    src={HeroImage}
                    alt="hero"
                    className="max-w-full lg:ml-auto"
                  />
                  <span className="absolute -bottom-8 -left-8 z-[-1]">
                    <svg
                      width="93"
                      height="93"
                      viewBox="0 0 93 93"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#38b773" />
                      <circle cx="2.5" cy="24.5" r="2.5" fill="#38b773" />
                      <circle cx="2.5" cy="46.5" r="2.5" fill="#38b773" />
                      <circle cx="2.5" cy="68.5" r="2.5" fill="#38b773" />
                      <circle cx="2.5" cy="90.5" r="2.5" fill="#38b773" />
                      <circle cx="24.5" cy="2.5" r="2.5" fill="#38b773" />
                      <circle cx="24.5" cy="24.5" r="2.5" fill="#38b773" />
                      <circle cx="24.5" cy="46.5" r="2.5" fill="#38b773" />
                      <circle cx="24.5" cy="68.5" r="2.5" fill="#38b773" />
                      <circle cx="24.5" cy="90.5" r="2.5" fill="#38b773" />
                      <circle cx="46.5" cy="2.5" r="2.5" fill="#38b773" />
                      <circle cx="46.5" cy="24.5" r="2.5" fill="#38b773" />
                      <circle cx="46.5" cy="46.5" r="2.5" fill="#38b773" />
                      <circle cx="46.5" cy="68.5" r="2.5" fill="#38b773" />
                      <circle cx="46.5" cy="90.5" r="2.5" fill="#38b773" />
                      <circle cx="68.5" cy="2.5" r="2.5" fill="#38b773" />
                      <circle cx="68.5" cy="24.5" r="2.5" fill="#38b773" />
                      <circle cx="68.5" cy="46.5" r="2.5" fill="#38b773" />
                      <circle cx="68.5" cy="68.5" r="2.5" fill="#38b773" />
                      <circle cx="68.5" cy="90.5" r="2.5" fill="#38b773" />
                      <circle cx="90.5" cy="2.5" r="2.5" fill="#38b773" />
                      <circle cx="90.5" cy="24.5" r="2.5" fill="#38b773" />
                      <circle cx="90.5" cy="46.5" r="2.5" fill="#38b773" />
                      <circle cx="90.5" cy="68.5" r="2.5" fill="#38b773" />
                      <circle cx="90.5" cy="90.5" r="2.5" fill="#38b773" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;