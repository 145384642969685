import React, { FC, ReactNode } from "react"
import { ReactComponent as IconLaptop } from "../assets/images/IconLaptop.svg"
import { ReactComponent as IconBrush } from "../assets/images/IconBrush.svg"
import { ReactComponent as IconDashboard } from "../assets/images/IconDashboard.svg"
import { ReactComponent as IconIot } from "../assets/images/IconIot.svg"
import { ReactComponent as IconMegaPhone } from "../assets/images/IconMegaPhone.svg"
import { ReactComponent as IconPhone } from "../assets/images/IconPhone.svg"

const ServicesSection = () => {
	return (
		<section
			id="services-section"
			className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]"
		>
			<div className="container mx-auto">
				<div className="-mx-4 flex flex-wrap">
					<div className="w-full px-4">
						<div className="mx-auto mb-12 max-w-[530px] text-center lg:mb-20">
							<span className="mb-2 block text-lg font-semibold text-primary">
								Our Services
							</span>
							<h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
								Creating Your Digital Future
							</h2>
							<p className="text-base text-body-color dark:text-dark-6">
								Our services are designed to help you achieve your goals in the
								digital world and drive success.
							</p>
						</div>
					</div>
				</div>

				<div className="relative">
					<div className="-mx-4 flex flex-wrap">
						<ServiceCard
							title="Website Development"
							details="Our Web Development services deliver robust, scalable, and user-friendly websites tailored to your business needs."
							icon={<IconLaptop />}
						/>
						<ServiceCard
							title="Graphic Designing"
							details="Our Graphic Designing services bring your vision to life with creative, eye-catching and impactful visuals."
							icon={<IconBrush />}
						/>
						<ServiceCard
							title="Internet Of Things"
							details="Our (IoT) solutions integrate smart technology into your business operations, providing efficient ways to connect and monitor."
							icon={<IconIot />}
						/>
						<ServiceCard
							title="SEO"
							details="Our SEO services boost your online visibility and drive organic traffic to your website ensuring your business stands out."
							icon={<IconDashboard />}
						/>
						<ServiceCard
							title="Mobile Application"
							details="Our App Dev services create intuitive and dynamic mobile applications tailored to your specific needs."
							icon={<IconPhone />}
						/>
						<ServiceCard
							title="Digital Marketing"
							details="Our Digital Marketing services encompass a range of strategies to promote your business online."
							icon={<IconMegaPhone />}
						/>
					</div>
					<div>
          <span className="hidden xl:block xl:absolute top-64 left-[23.1rem] z-[-1]">
							<svg
								width={107}
								height={134}
								viewBox="0 0 107 134"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="104.999"
									cy={132}
									r="1.66667"
									transform="rotate(180 104.999 132)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 104.999 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 104.999 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={88}
									r="1.66667"
									transform="rotate(180 104.999 88)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 104.999 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={45}
									r="1.66667"
									transform="rotate(180 104.999 45)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={16}
									r="1.66667"
									transform="rotate(180 104.999 16)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={59}
									r="1.66667"
									transform="rotate(180 104.999 59)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 104.999 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 104.999 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 90.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 90.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 90.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 90.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 90.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 90.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 90.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 90.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 90.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 90.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={132}
									r="1.66667"
									transform="rotate(180 75.6654 132)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 31.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 75.6654 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 31.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 75.6654 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 31.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={88}
									r="1.66667"
									transform="rotate(180 75.6654 88)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 31.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 75.6654 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 31.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={45}
									r="1.66667"
									transform="rotate(180 75.6654 45)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 31.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={16}
									r="1.66667"
									transform="rotate(180 75.6654 16)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 31.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={59}
									r="1.66667"
									transform="rotate(180 75.6654 59)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 31.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 75.6654 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 31.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 75.6654 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 31.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 60.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 17.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 60.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 17.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 60.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 17.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 60.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 17.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 60.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 17.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 60.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 17.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 60.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 17.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 60.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 17.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 60.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 17.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 60.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 17.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 46.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={132}
									r="1.66667"
									transform="rotate(180 2.66536 132)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 46.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 2.66536 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 46.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 2.66536 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 46.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={88}
									r="1.66667"
									transform="rotate(180 2.66536 88)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 46.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 2.66536 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 46.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={45}
									r="1.66667"
									transform="rotate(180 2.66536 45)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 46.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={16}
									r="1.66667"
									transform="rotate(180 2.66536 16)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 46.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={59}
									r="1.66667"
									transform="rotate(180 2.66536 59)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 46.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 2.66536 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 46.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 2.66536 1.66665)"
									fill="#13C296"
								/>
							</svg>
						</span>
            <span className="hidden xl:block xl:absolute top-64 right-[23.2rem] z-[-1]">
							<svg
								width={107}
								height={134}
								viewBox="0 0 107 134"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="104.999"
									cy={132}
									r="1.66667"
									transform="rotate(180 104.999 132)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 104.999 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 104.999 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={88}
									r="1.66667"
									transform="rotate(180 104.999 88)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 104.999 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={45}
									r="1.66667"
									transform="rotate(180 104.999 45)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={16}
									r="1.66667"
									transform="rotate(180 104.999 16)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={59}
									r="1.66667"
									transform="rotate(180 104.999 59)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 104.999 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 104.999 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 90.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 90.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 90.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 90.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 90.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 90.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 90.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 90.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 90.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 90.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={132}
									r="1.66667"
									transform="rotate(180 75.6654 132)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 31.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 75.6654 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 31.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 75.6654 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 31.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={88}
									r="1.66667"
									transform="rotate(180 75.6654 88)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 31.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 75.6654 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 31.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={45}
									r="1.66667"
									transform="rotate(180 75.6654 45)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 31.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={16}
									r="1.66667"
									transform="rotate(180 75.6654 16)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 31.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={59}
									r="1.66667"
									transform="rotate(180 75.6654 59)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 31.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 75.6654 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 31.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 75.6654 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 31.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 60.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 17.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 60.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 17.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 60.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 17.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 60.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 17.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 60.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 17.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 60.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 17.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 60.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 17.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 60.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 17.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 60.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 17.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 60.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 17.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 46.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={132}
									r="1.66667"
									transform="rotate(180 2.66536 132)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 46.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 2.66536 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 46.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 2.66536 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 46.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={88}
									r="1.66667"
									transform="rotate(180 2.66536 88)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 46.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 2.66536 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 46.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={45}
									r="1.66667"
									transform="rotate(180 2.66536 45)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 46.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={16}
									r="1.66667"
									transform="rotate(180 2.66536 16)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 46.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={59}
									r="1.66667"
									transform="rotate(180 2.66536 59)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 46.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 2.66536 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 46.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 2.66536 1.66665)"
									fill="#13C296"
								/>
							</svg>
						</span>
						<span className="hidden xl:block xl:absolute bottom-36 -right-12 z-[-1]">
							<svg
								width={100}
								height={100}
								viewBox="0 0 100 100"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
									fill="#38B773"
								/>
							</svg>
						</span>
						<span className="hidden xl:block xl:absolute bottom-0 -right-12 z-[-1]">
							<svg
								width={107}
								height={134}
								viewBox="0 0 107 134"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="104.999"
									cy={132}
									r="1.66667"
									transform="rotate(180 104.999 132)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 104.999 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 104.999 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={88}
									r="1.66667"
									transform="rotate(180 104.999 88)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 104.999 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={45}
									r="1.66667"
									transform="rotate(180 104.999 45)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={16}
									r="1.66667"
									transform="rotate(180 104.999 16)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy={59}
									r="1.66667"
									transform="rotate(180 104.999 59)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 104.999 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="104.999"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 104.999 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 90.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 90.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 90.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 90.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 90.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 90.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 90.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 90.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 90.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="90.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 90.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={132}
									r="1.66667"
									transform="rotate(180 75.6654 132)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 31.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 75.6654 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 31.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 75.6654 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 31.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={88}
									r="1.66667"
									transform="rotate(180 75.6654 88)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 31.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 75.6654 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 31.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={45}
									r="1.66667"
									transform="rotate(180 75.6654 45)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 31.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={16}
									r="1.66667"
									transform="rotate(180 75.6654 16)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 31.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy={59}
									r="1.66667"
									transform="rotate(180 75.6654 59)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 31.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 75.6654 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 31.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="75.6654"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 75.6654 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="31.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 31.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={132}
									r="1.66667"
									transform="rotate(180 60.9993 132)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 17.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 60.9993 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 17.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 60.9993 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 17.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={88}
									r="1.66667"
									transform="rotate(180 60.9993 88)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 17.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 60.9993 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 17.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={45}
									r="1.66667"
									transform="rotate(180 60.9993 45)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 17.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={16}
									r="1.66667"
									transform="rotate(180 60.9993 16)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 17.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy={59}
									r="1.66667"
									transform="rotate(180 60.9993 59)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 17.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 60.9993 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 17.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="60.9993"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 60.9993 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="17.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 17.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={132}
									r="1.66667"
									transform="rotate(180 46.3333 132)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={132}
									r="1.66667"
									transform="rotate(180 2.66536 132)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 46.3333 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="117.333"
									r="1.66667"
									transform="rotate(180 2.66536 117.333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 46.3333 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="102.667"
									r="1.66667"
									transform="rotate(180 2.66536 102.667)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={88}
									r="1.66667"
									transform="rotate(180 46.3333 88)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={88}
									r="1.66667"
									transform="rotate(180 2.66536 88)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 46.3333 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="73.3333"
									r="1.66667"
									transform="rotate(180 2.66536 73.3333)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={45}
									r="1.66667"
									transform="rotate(180 46.3333 45)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={45}
									r="1.66667"
									transform="rotate(180 2.66536 45)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={16}
									r="1.66667"
									transform="rotate(180 46.3333 16)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={16}
									r="1.66667"
									transform="rotate(180 2.66536 16)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy={59}
									r="1.66667"
									transform="rotate(180 46.3333 59)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy={59}
									r="1.66667"
									transform="rotate(180 2.66536 59)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 46.3333 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="30.6666"
									r="1.66667"
									transform="rotate(180 2.66536 30.6666)"
									fill="#13C296"
								/>
								<circle
									cx="46.3333"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 46.3333 1.66665)"
									fill="#13C296"
								/>
								<circle
									cx="2.66536"
									cy="1.66665"
									r="1.66667"
									transform="rotate(180 2.66536 1.66665)"
									fill="#13C296"
								/>
							</svg>
						</span>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ServicesSection

const ServiceCard: FC<{ icon: ReactNode; title: string; details: string }> = ({
	icon,
	title,
	details,
}) => {
	return (
		<>
			<div className="w-full px-4 md:w-1/2 lg:w-1/3">
				<div className="mb-9 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10">
					<div className="mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
						{icon}
					</div>
					<h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
						{title}
					</h4>
					<p className="text-body-color dark:text-dark-6">{details}</p>
				</div>
			</div>
		</>
	)
}
