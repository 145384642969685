import React, { FC, ReactNode } from "react"
import BigLogo from "../assets/images/BigLogo.svg"
import { ReactComponent as IconRegister } from "../assets/images/IconRegister.svg"
import { ReactComponent as IconBuilding } from "../assets/images/IconBuilding.svg"
// import { ReactComponent as IconInsta } from "../assets/images/IconInsta.svg"
import { Link, useNavigate } from "react-router-dom"
import { scroller } from "react-scroll"

const Footer = () => {
	return (
		<>
			<footer className="relative z-10 bg-white pt-20 dark:bg-dark lg:pt-[120px]">
				<div className="container">
					<div className="-mx-4 flex flex-wrap justify-around">
						<div className="w-full px-4 sm:w-2/3 lg:w-3/12">
							<div className="mb-10 w-full">
								<Link to="/#" className="mb-6 inline-block max-w-[160px]">
									<img src={BigLogo} alt="logo" height={40} className="" />
								</Link>
								<p className="mb-5 text-base text-body-color dark:text-dark-6">
									At Gig Masters, we specialize in online services designed to
									transform your digital presence.
								</p>

								<h4 className="text-lg font-semibold text-dark dark:text-white">
									Headquarter
								</h4>
								<p className="flex mt-3 items-center text-sm font-medium text-dark dark:text-white">
									<span className="mr-3 text-primary">
										<IconBuilding />
									</span>
									<p className="text-body-color font-md">
										CASSANO MAGNAGO (VA) VIA FORO SAN MARTINO 22 CAP 21012
									</p>
								</p>
								<p className="flex mt-3 items-center text-sm font-medium text-dark dark:text-white">
									<span className="mr-3 text-primary">
										<IconRegister />
									</span>
									<p className="text-body-color font-md">
										<span className="font-bold text-black">Reg No. </span>
										SJDMRN85B19Z236Z
									</p>
								</p>
							</div>
						</div>

						<LinkGroup header="Services">
							<FooterScrollLink
								section="services-section"
								label="Web Development"
							/>
							<FooterScrollLink
								section="services-section"
								label="Graphics Design"
							/>
							<FooterScrollLink
								section="services-section"
								label="SEO & Content Writing"
							/>
							<FooterScrollLink
								section="services-section"
								label="Digital Marketing"
							/>
							<FooterScrollLink
								section="services-section"
								label="Internet Of Things"
							/>
							<FooterScrollLink
								section="services-section"
								label="Mobile Application"
							/>
						</LinkGroup>
						<LinkGroup header="Quick Links">
							<FooterScrollLink section="nav-section" label="Home" />
							<FooterScrollLink section="about-section" label="About" />
							<FooterScrollLink section="contact-section" label="Contact" />
							<FooterLink link="/careers" label="Career" />
						</LinkGroup>

						<div className="w-full px-4 sm:w-1/2 lg:w-3/12">
							<div className="mb-10 w-full">
								<h4 className="mb-9 text-lg font-semibold text-dark dark:text-white">
									Follow Us On
								</h4>
								<div className="mb-6 flex items-center">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.facebook.com/Gigmasaters.it"
										className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-stroke text-dark hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4"
									>
										<svg
											width="8"
											height="16"
											viewBox="0 0 8 16"
											className="fill-current"
										>
											<path d="M7.43902 6.4H6.19918H5.75639V5.88387V4.28387V3.76774H6.19918H7.12906C7.3726 3.76774 7.57186 3.56129 7.57186 3.25161V0.516129C7.57186 0.232258 7.39474 0 7.12906 0H5.51285C3.76379 0 2.54609 1.44516 2.54609 3.5871V5.83226V6.34839H2.10329H0.597778C0.287819 6.34839 0 6.63226 0 7.04516V8.90323C0 9.26452 0.243539 9.6 0.597778 9.6H2.05902H2.50181V10.1161V15.3032C2.50181 15.6645 2.74535 16 3.09959 16H5.18075C5.31359 16 5.42429 15.9226 5.51285 15.8194C5.60141 15.7161 5.66783 15.5355 5.66783 15.3806V10.1419V9.62581H6.13276H7.12906C7.41688 9.62581 7.63828 9.41935 7.68256 9.10968V9.08387V9.05806L7.99252 7.27742C8.01466 7.09677 7.99252 6.89032 7.85968 6.68387C7.8154 6.55484 7.61614 6.42581 7.43902 6.4Z" />
										</svg>
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://twitter.com/GigMaasters"
										className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-stroke text-dark hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4"
									>
										<svg
											width="16"
											height="12"
											viewBox="0 0 16 12"
											className="fill-current"
										>
											<path d="M14.2194 2.06654L15.2 0.939335C15.4839 0.634051 15.5613 0.399217 15.5871 0.2818C14.8129 0.704501 14.0903 0.845401 13.6258 0.845401H13.4452L13.3419 0.751468C12.7226 0.258317 11.9484 0 11.1226 0C9.31613 0 7.89677 1.36204 7.89677 2.93542C7.89677 3.02935 7.89677 3.17025 7.92258 3.26419L8 3.73386L7.45806 3.71037C4.15484 3.61644 1.44516 1.03327 1.00645 0.587084C0.283871 1.76125 0.696774 2.88845 1.13548 3.59296L2.0129 4.90802L0.619355 4.20352C0.645161 5.18982 1.05806 5.96477 1.85806 6.52838L2.55484 6.99804L1.85806 7.25636C2.29677 8.45401 3.27742 8.94716 4 9.13503L4.95484 9.36986L4.05161 9.93346C2.60645 10.8728 0.8 10.8024 0 10.7319C1.62581 11.7652 3.56129 12 4.90323 12C5.90968 12 6.65806 11.9061 6.83871 11.8356C14.0645 10.2857 14.4 4.41487 14.4 3.2407V3.07632L14.5548 2.98239C15.4323 2.23092 15.7935 1.8317 16 1.59687C15.9226 1.62035 15.8194 1.66732 15.7161 1.6908L14.2194 2.06654Z" />
										</svg>
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.instagram.com/gigmasters_it"
										className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-stroke text-dark hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4"
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											className="fill-current"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0 7.11111C0 3.75911 -1.05964e-07 2.08267 1.04178 1.04178C2.08267 -1.05964e-07 3.75911 0 7.11111 0H8.88889C12.2409 0 13.9173 -1.05964e-07 14.9582 1.04178C16 2.08267 16 3.75911 16 7.11111V8.88889C16 12.2409 16 13.9173 14.9582 14.9582C13.9173 16 12.2409 16 8.88889 16H7.11111C3.75911 16 2.08267 16 1.04178 14.9582C-1.05964e-07 13.9173 0 12.2409 0 8.88889V7.11111ZM13.3333 4C13.3333 4.35362 13.1929 4.69276 12.9428 4.94281C12.6928 5.19286 12.3536 5.33333 12 5.33333C11.6464 5.33333 11.3072 5.19286 11.0572 4.94281C10.8071 4.69276 10.6667 4.35362 10.6667 4C10.6667 3.64638 10.8071 3.30724 11.0572 3.05719C11.3072 2.80714 11.6464 2.66667 12 2.66667C12.3536 2.66667 12.6928 2.80714 12.9428 3.05719C13.1929 3.30724 13.3333 3.64638 13.3333 4ZM9.77778 8.88889C9.77778 9.36038 9.59048 9.81257 9.25708 10.146C8.92368 10.4794 8.4715 10.6667 8 10.6667C7.5285 10.6667 7.07632 10.4794 6.74292 10.146C6.40952 9.81257 6.22222 9.36038 6.22222 8.88889C6.22222 8.41739 6.40952 7.96521 6.74292 7.63181C7.07632 7.29841 7.5285 7.11111 8 7.11111C8.4715 7.11111 8.92368 7.29841 9.25708 7.63181C9.59048 7.96521 9.77778 8.41739 9.77778 8.88889ZM11.5556 8.88889C11.5556 9.83188 11.181 10.7363 10.5142 11.403C9.84736 12.0698 8.94299 12.4444 8 12.4444C7.05701 12.4444 6.15264 12.0698 5.48584 11.403C4.81905 10.7363 4.44444 9.83188 4.44444 8.88889C4.44444 7.9459 4.81905 7.04153 5.48584 6.37473C6.15264 5.70794 7.05701 5.33333 8 5.33333C8.94299 5.33333 9.84736 5.70794 10.5142 6.37473C11.181 7.04153 11.5556 7.9459 11.5556 8.88889Z"
											/>
										</svg>
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/company/gig-masters"
										className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-stroke text-dark hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3 dark:text-white dark:hover:border-primary sm:mr-4 lg:mr-3 xl:mr-4"
									>
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											className="fill-current"
										>
											<path d="M13.0214 0H1.02084C0.453707 0 0 0.451613 0 1.01613V12.9839C0 13.5258 0.453707 14 1.02084 14H12.976C13.5432 14 13.9969 13.5484 13.9969 12.9839V0.993548C14.0422 0.451613 13.5885 0 13.0214 0ZM4.15142 11.9H2.08705V5.23871H4.15142V11.9ZM3.10789 4.3129C2.42733 4.3129 1.90557 3.77097 1.90557 3.11613C1.90557 2.46129 2.45002 1.91935 3.10789 1.91935C3.76577 1.91935 4.31022 2.46129 4.31022 3.11613C4.31022 3.77097 3.81114 4.3129 3.10789 4.3129ZM11.9779 11.9H9.9135V8.67097C9.9135 7.90323 9.89082 6.8871 8.82461 6.8871C7.73571 6.8871 7.57691 7.74516 7.57691 8.60323V11.9H5.51254V5.23871H7.53154V6.16452H7.55423C7.84914 5.62258 8.50701 5.08065 9.52785 5.08065C11.6376 5.08065 12.0232 6.43548 12.0232 8.2871V11.9H11.9779Z" />
										</svg>
									</a>
								</div>
								<div>
									<h4 className="text-lg font-semibold text-dark dark:text-white">
										Work Place
									</h4>
									<p className="flex mt-3 items-center text-sm font-medium text-dark dark:text-white">
										<span className="mr-3 text-primary">
											<svg
												width="20"
												height="20"
												viewBox="0 0 32 32"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M30.6 11.8002L17.7 3.5002C16.65 2.8502 15.3 2.8502 14.3 3.5002L1.39998 11.8002C0.899983 12.1502 0.749983 12.8502 1.04998 13.3502C1.39998 13.8502 2.09998 14.0002 2.59998 13.7002L3.44998 13.1502V25.8002C3.44998 27.5502 4.84998 28.9502 6.59998 28.9502H25.4C27.15 28.9502 28.55 27.5502 28.55 25.8002V13.1502L29.4 13.7002C29.6 13.8002 29.8 13.9002 30 13.9002C30.35 13.9002 30.75 13.7002 30.95 13.4002C31.3 12.8502 31.15 12.1502 30.6 11.8002ZM13.35 26.7502V18.5002C13.35 18.0002 13.75 17.6002 14.25 17.6002H17.75C18.25 17.6002 18.65 18.0002 18.65 18.5002V26.7502H13.35ZM26.3 25.8002C26.3 26.3002 25.9 26.7002 25.4 26.7002H20.9V18.5002C20.9 16.8002 19.5 15.4002 17.8 15.4002H14.3C12.6 15.4002 11.2 16.8002 11.2 18.5002V26.7502H6.69998C6.19998 26.7502 5.79998 26.3502 5.79998 25.8502V11.7002L15.5 5.4002C15.8 5.2002 16.2 5.2002 16.5 5.4002L26.3 11.7002V25.8002Z"
													fill="currentColor"
												/>
											</svg>
										</span>
										<p className="text-body-color font-md">
											388 B-2, Johar Town, Lahore
										</p>
									</p>
									<p className="flex  mt-3 items-center text-sm font-medium text-dark dark:text-white">
										<span className="mr-3 text-primary">
											<svg
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_941_15626)">
													<path
														d="M15.1875 19.4688C14.3438 19.4688 13.375 19.25 12.3125 18.8438C10.1875 18 7.84377 16.375 5.75002 14.2813C3.65627 12.1875 2.03127 9.84377 1.18752 7.68752C0.250019 5.37502 0.343769 3.46877 1.43752 2.40627C1.46877 2.37502 1.53127 2.34377 1.56252 2.31252L4.18752 0.750025C4.84377 0.375025 5.68752 0.562525 6.12502 1.18752L7.96877 3.93753C8.40627 4.59378 8.21877 5.46877 7.59377 5.90627L6.46877 6.68752C7.28127 8.00002 9.59377 11.2188 13.2813 13.5313L13.9688 12.5313C14.5 11.7813 15.3438 11.5625 16.0313 12.0313L18.7813 13.875C19.4063 14.3125 19.5938 15.1563 19.2188 15.8125L17.6563 18.4375C17.625 18.5 17.5938 18.5313 17.5625 18.5625C17 19.1563 16.1875 19.4688 15.1875 19.4688ZM2.37502 3.46878C1.78127 4.12503 1.81252 5.46877 2.50002 7.18752C3.28127 9.15627 4.78127 11.3125 6.75002 13.2813C8.68752 15.2188 10.875 16.7188 12.8125 17.5C14.5 18.1875 15.8438 18.2188 16.5313 17.625L18.0313 15.0625C18.0313 15.0313 18.0313 15.0313 18.0313 15L15.2813 13.1563C15.2813 13.1563 15.2188 13.1875 15.1563 13.2813L14.4688 14.2813C14.0313 14.9063 13.1875 15.0938 12.5625 14.6875C8.62502 12.25 6.18752 8.84377 5.31252 7.46877C4.90627 6.81252 5.06252 5.96878 5.68752 5.53128L6.81252 4.75002V4.71878L4.96877 1.96877C4.96877 1.93752 4.93752 1.93752 4.90627 1.96877L2.37502 3.46878Z"
														fill="currentColor"
													/>
													<path
														d="M18.3125 8.90633C17.9375 8.90633 17.6563 8.62508 17.625 8.25008C17.375 5.09383 14.7813 2.56258 11.5938 2.34383C11.2188 2.31258 10.9063 2.00008 10.9375 1.59383C10.9688 1.21883 11.2813 0.906333 11.6875 0.937583C15.5625 1.18758 18.7188 4.25008 19.0313 8.12508C19.0625 8.50008 18.7813 8.84383 18.375 8.87508C18.375 8.90633 18.3438 8.90633 18.3125 8.90633Z"
														fill="currentColor"
													/>
													<path
														d="M15.2187 9.18755C14.875 9.18755 14.5625 8.93755 14.5312 8.56255C14.3437 6.87505 13.0312 5.56255 11.3437 5.3438C10.9687 5.31255 10.6875 4.93755 10.7187 4.56255C10.75 4.18755 11.125 3.9063 11.5 3.93755C13.8437 4.2188 15.6562 6.0313 15.9375 8.37505C15.9687 8.75005 15.7187 9.0938 15.3125 9.1563C15.25 9.18755 15.2187 9.18755 15.2187 9.18755Z"
														fill="currentColor"
													/>
												</g>
												<defs>
													<clipPath id="clip0_941_15626">
														<rect width="20" height="20" fill="white" />
													</clipPath>
												</defs>
											</svg>
										</span>
										<p className="text-body-color font-md">(+92) 340 1115566</p>
									</p>
									<p className="flex  mt-3 items-center text-sm font-medium text-dark dark:text-white">
										<span className="mr-3 text-primary">
											<svg
												width="20"
												height="20"
												viewBox="0 0 32 32"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
													fill="currentColor"
												/>
											</svg>
										</span>
										<p className="text-body-color font-md">
											contact@gigmasters.it
										</p>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<span className="absolute bottom-0 left-0 z-[-1]">
						<svg
							width={217}
							height={229}
							viewBox="0 0 217 229"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
								fill="url(#paint0_linear_1179_5)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_1179_5"
									x1="76.5"
									y1={281}
									x2="76.5"
									y2="1.22829e-05"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#3056D3" stopOpacity="0.08" />
									<stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
								</linearGradient>
							</defs>
						</svg>
					</span>
					<span className="absolute right-10 top-10 z-[-1]">
						<svg
							width={75}
							height={75}
							viewBox="0 0 75 75"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
								fill="url(#paint0_linear_1179_4)"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_1179_4"
									x1="-1.63917e-06"
									y1="37.5"
									x2={75}
									y2="37.5"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#13C296" stopOpacity="0.31" />
									<stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
								</linearGradient>
							</defs>
						</svg>
					</span>
				</div>
				<div className="mt-3">
					<p className="py-4 border-t border-gray-4 text-center text-sm text-body-color dark:text-dark-6">
						© 2024 Gig Masters. All Rights Reserverd
					</p>
				</div>
			</footer>
		</>
	)
}

export default Footer

const LinkGroup: FC<{ children: ReactNode; header: string }> = ({
	children,
	header,
}) => {
	return (
		<>
			<div className="w-full px-4 sm:w-1/2 lg:w-2/12">
				<div className="mb-10 w-full">
					<h4 className="mb-9 text-lg font-semibold text-dark dark:text-white">
						{header}
					</h4>
					<ul className="space-y-3 cursor-pointer">{children}</ul>
				</div>
			</div>
		</>
	)
}

const FooterScrollLink: FC<{ section: string; label: string }> = ({
	section,
	label,
}) => {
	const navigate = useNavigate()

	const handleClick = () => {
		navigate("/", { replace: true })
		setTimeout(() => {
			scroller.scrollTo(section, {
				duration: 500,
				delay: 0,
				smooth: "easeInOutQuart",
			})
		}, 100)
	}
	return (
		<li>
			<div
				onClick={handleClick}
				className="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6"
			>
				{label}
			</div>
		</li>
	)
}

const FooterLink: FC<{ link: string; label: string }> = ({ link, label }) => {
	return (
		<li>
			<Link
				to={link}
				className="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6"
			>
				{label}
			</Link>
		</li>
	)
}
