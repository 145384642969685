import axios from "axios"
import { FC, useEffect, useState } from "react"
import CareerCardSkeleton from "../Skeletons/CareerCardSkeleton"
import { Link } from "react-router-dom"

interface CareerListing {
	id: number
	job_title: string
	job_description: string
	job_category: string
	salary: number
	job_requirements: string
	image: string
}

const Career = () => {
	const [showCard, setShowCard] = useState("all")
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [careerListing, setCareerListing] = useState<CareerListing[]>([])
	const [categories, setCategories] = useState<string[]>([])

	const handleProject = (category: string) => {
		setShowCard(category)
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				await axios
					.get(`${process.env.REACT_APP_API_URL}/users/joblistings/`)
					.then((response) => setCareerListing(response.data))
					.catch((e) => console.error(e))
			} catch (err: any) {
				console.error(err.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		const filterCategories = () => {
			const uniqueCategories: string[] = []
			careerListing.forEach((job) => {
				if (!uniqueCategories.includes(job.job_category)) {
					uniqueCategories.push(job.job_category)
				}
			})
			setCategories(uniqueCategories)
		}

		filterCategories()
	}, [careerListing])

	return (
		<>
			<section className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark">
				<div className="container mx-auto">
					<div className="flex flex-wrap -mx-4">
						<div className="w-full px-4">
							<div className="mx-auto mb-[60px] max-w-[510px] text-center">
								<span className="text-primary mb-2 block text-lg font-semibold">
									Careers
								</span>
								<h2 className="text-dark mb-3 text-3xl leading-[1.208] font-bold sm:text-4xl md:text-[40px]">
									Join Our Team
								</h2>
								<p className="text-body-color text-base dark:text-dark-6">
									At Gig Masters, we are always looking for talented and
									passionate individuals to join our team.
								</p>
							</div>
						</div>
					</div>

					<div className="w-full flex flex-wrap justify-center -mx-4">
						<div className="w-full px-4">
							<ul className="flex flex-wrap justify-center mb-12 space-x-1">
								<li className="mb-1">
									<button
										onClick={() => handleProject("all")}
										className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
											showCard === "all"
												? "activeClasses bg-primary text-white"
												: "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
										}`}
									>
										All Jobs
									</button>
								</li>
								{categories.length > 0 &&
									categories.map((item, index) => (
										<li key={index} className="mb-1">
											<button
												onClick={() => handleProject(item)}
												className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
													showCard === item
														? "activeClasses bg-primary text-white"
														: "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
												}`}
											>
												{item}
											</button>
										</li>
									))}
							</ul>
						</div>
					</div>
					<div className="flex flex-wrap -mx-4">
						{isLoading &&
							[...Array(3)].map((_, index) => (
								<CareerCardSkeleton key={index} />
							))}
						{!isLoading &&
							careerListing.map((data, index) => (
								<PortfolioCard
									key={index}
									ImageHref={data.image}
									category={data.job_category}
									title={data.job_title}
									button="Apply Now"
									buttonHref={`/apply-job/${data.id}`}
									showCard={showCard}
								/>
							))}
					</div>
				</div>
			</section>
		</>
	)
}

export default Career

const PortfolioCard: FC<{
	showCard: string
	category: string
	ImageHref: string
	title: string
	button: string
	buttonHref: string
}> = ({ showCard, category, ImageHref, title, button, buttonHref }) => {
	return (
		<>
			<div
				className={`w-full px-4 md:w-1/2 xl:w-1/3 ${
					showCard === "all" || showCard === category ? "block" : "hidden"
				}`}
			>
				<div className="relative mb-12">
					<div className="overflow-hidden rounded-[10px]">
						<img
							src={ImageHref}
							alt="portfolio"
							className="w-full h-72 object-cover"
						/>
					</div>
					<div className="relative z-10 mx-7 -mt-20 rounded-lg bg-white dark:bg-dark-2 py-[34px] px-3 text-center shadow-portfolio dark:shadow-box-dark">
						<span className="text-primary mb-2 block text-sm font-medium">
							{category}
						</span>
						<h3 className="text-dark dark:text-white mb-5 text-xl font-bold">
							{title}
						</h3>
						<Link
							to={buttonHref}
							className="text-body-color dark:text-dark-6 hover:border-primary hover:bg-primary inline-block rounded-md border border-stroke dark:border-dark-3 py-[10px] px-7 text-sm font-medium transition hover:text-white"
						>
							{button}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}
