import React, { FC, useState } from "react"
import Logo from "../assets/images/Mainlogo.svg"
import ListItem from "./ListItem"
import { Element } from "react-scroll"
import { Link } from "react-router-dom"

const Navbar:FC = () => {
	const [open, setOpen] = useState(false)

	return (
		<Element name="nav-section">
			<header id="nav-section" className={`flex w-full items-center bg-white dark:bg-dark px-4`}>
			<div className="container mx-auto">
				<div className="relative -mx-4 flex items-center justify-between">
					<div className="max-w-full px-4">
						<Link to="/#" className="block w-full py-5">
							<img src={Logo} alt="logo" width={100} height={50} className="" />
						</Link>
					</div>
					<div className="flex w-full items-center justify-between px-4">
						<div className="lg:flex-grow">
							<button
								onClick={() => setOpen(!open)}
								id="navbarToggler"
								className={` ${
									open && "navbarTogglerActive"
								} absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
							>
								<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
								<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
								<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
							</button>
							<nav
								// :className="!navbarOpen && 'hidden' "
								id="navbarCollapse"
								className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white px-6 py-5 shadow dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent z-20 lg:flex-grow ${
									!open && "hidden"
								} `}
							>
								<ul className="block lg:flex lg:justify-center">
									<ListItem section="nav-section">Home</ListItem>
									<ListItem section="services-section">Services</ListItem>
									<ListItem section="about-section">About</ListItem>
									<ListItem section="contact-section">Contact</ListItem>
								</ul>
							</nav>
						</div>
						<div className="hidden justify-end pr-16 sm:flex lg:pr-0">
							<Link
								to="/careers"
								className="rounded-md bg-primary px-7 py-3 text-base font-medium text-white hover:bg-primary/90"
							>
								Careers
							</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
		</Element>
	)
}

export default Navbar
