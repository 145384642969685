import React, { FC, ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { scroller } from "react-scroll"

const ListItem: FC<{ children: ReactNode, section: string }> = ({
	children,
	section,
}) => {
	const navigate = useNavigate()

	const handleClick = () => {
		navigate('/', {replace: true})
		setTimeout(() => {
			scroller.scrollTo(section, {
				duration: 500,
				delay: 0,
				smooth: 'easeInOutQuart'
			})
		}, 100)
	}

	return (
		<li>
			<div 
				onClick={handleClick}
				className="flex py-2 text-base font-medium text-body-color hover:text-dark dark:text-dark-6 dark:hover:text-white lg:ml-6 lg:mr-6 lg:inline-flex cursor-pointer"
			>
				{children}
			</div>
		</li>
	)
}

export default ListItem